<template>
  <b-card class="detail-return-result">
    <div
      class="justify-center items-center flex h-full"
      style="flex: auto; min-height: 600px;"
      v-if="isLoading"
    >
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else class="flex">
      <div class="sidebar">
        <div style="padding-right: 8px;">
          <b-form-input
            v-model="keyword"
            :placeholder="$t('lbl_search')"
            :debounce="500"
            class="mb-1"
          ></b-form-input>
          <div class="justify-center items-center flex h-full" v-if="isLoadingOrder">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
          <ListOrders
            v-else-if="listOrders.length > 0"
            :pagination="pagination"
            class="list-orders"
            :isLoadingOrder="isLoadingMoreOrder"
            :listOrders="listOrders"
            :selectedOrder="selectedOrder"
            @selectItem="handleSelectOrder"
            @scroll="handleScroll"
          ></ListOrders>
          <div v-else>Không tìm thấy đơn hàng nào!</div>
          <div
            class="justify-center items-center flex w-100"
            style="height: 40px;"
            v-show="isLoadingMoreOrder"
          >
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
        </div>
      </div>
      <div style="flex: auto; overflow: auto" class="result flex flex-col justify-between">
        <div class="flex justify-between items-start gap-3 pb-2">
          <div class="order-info flex flex-col pl-2">
            <span>
              <b>Đơn hàng:</b>
              {{selectedOrder && selectedOrder.order_code}} - {{selectedOrder && selectedOrder.contact_name}}
            </span>
            <span>
              <b>Thời gian đặt:</b>
              {{ selectedOrder && selectedOrder.order_date ? appUtils.formatDateTime(selectedOrder.order_date) : '-'}}
            </span>
          </div>
          <div
            class="flex align-items-center justify-content-end gap-3"
            v-if="selectedOrder.product_order && selectedOrder.product_order.delivery_status !== 2"
          >
            <b-button
              variant="primary"
              @click="handleConfirmComplete"
              :disabled="selectedOrder.result_status !== 3"
            >Xác nhận hoàn thành đơn hàng</b-button>
          </div>
          <div v-else class="flex flex-col">
            <b-badge variant="info" class="text-base">Đơn hàng đã hoàn thành</b-badge>
            <small
              v-if="selectedOrder.product_order && selectedOrder.product_order.user_info && selectedOrder.product_order.user_info.id"
            >
              Xác nhận bởi
              <b>{{ selectedOrder.product_order && selectedOrder.product_order.user_info && selectedOrder.product_order.user_info.name }}</b>
              <br />vào lúc
              <b>{{ appUtils.formatDateTime(selectedOrder.product_order && selectedOrder.product_order.delivery_at) }}</b>
            </small>
          </div>
        </div>
        <div class="result-content">
          <div>
            <ProcessorResult
              title="KQ thực hiện bởi đơn vị chạy máy"
              :selectedOrder="selectedOrder"
              :formUpload="formUploadProcessor"
              @result_processor="setResultProcessor"
              type="processor"
            >
              <template #button>
                <div class="flex items-center justify-content-end">
                  <b-button
                    variant="outline-primary"
                    @click="handleUpdate"
                    v-b-tooltip.hover.top="selectedOrder.product_order && selectedOrder.product_order.delivery_status === 2 ? 'Đơn hàng đã hoàn thành, bạn không thể cập nhật kết quả' : ''"
                    :disabled="selectedOrder.product_order && selectedOrder.product_order.delivery_status === 2"
                    class="cursor-pointer"
                  >Cập nhật KQ</b-button>
                </div>
              </template>
              <template v-slot:status v-if="this.formUploadSupplier.length ===  0">
                <b-badge
                  :variant="getResultStatus(selectedOrder && selectedOrder.result_status).color"
                >{{ getResultStatus(selectedOrder && selectedOrder.result_status).label }}</b-badge>
              </template>
            </ProcessorResult>
            <p
              class="text-danger font-bold ml-2"
            >Lưu ý: Bệnh nhân sẽ nhận được Phiếu kết quả được cập nhật/bổ sung gần nhất</p>
            <ProcessorResult
              v-if="this.formUploadSupplier.length > 0"
              title="KQ đã tải lên"
              :selectedOrder="selectedOrder"
              type="supplier"
              :formUpload="formUploadSupplier"
              @result_supplier="setResultSupplier"
            >
              <template v-slot:status>
                <b-badge
                  :variant="getResultStatus(selectedOrder && selectedOrder.result_status).color"
                >{{ getResultStatus(selectedOrder && selectedOrder.result_status).label }}</b-badge>
              </template>
            </ProcessorResult>
          </div>
        </div>
        <div class="flex align-items-center justify-content-end gap-3 footer">
          <!-- <b-button variant="outline-secondary" @click="hide">Đóng</b-button> -->
          <b-button
            variant="primary"
            class="cursor-pointer"
            @click="onSubmit"
            v-b-tooltip.hover.top="selectedOrder.product_order && selectedOrder.product_order.delivery_status === 2 ? 'Đơn hàng đã hoàn thành, bạn không thể trả kết quả' : ''"
            :disabled="formUploadProcessor.length === 0 && formUploadSupplier.length === 0 || selectedOrder.product_order && selectedOrder.product_order.delivery_status === 2"
          >Gửi kết quả</b-button>
        </div>
      </div>
    </div>
    <SildebarReturnResultSupplier
      :is-show-slidebar.sync="isShowSlideBar"
      :selectedOrder="selectedOrder"
      :formUpload="formUploadSupplier"
      :listDisabled="listDisabled"
      @success="handleReload"
      @cancel-form="handleCancelForm"
    />
  </b-card>
</template>
<script>
import ListOrders from './ListOrders.vue'
import ProcessorResult from './ProcessorResult.vue'
import appUtils from '@/utils/appUtils'
import Vue from 'vue'
import {
  RESULT_TYPE,
  PRODUCT_TYPES,
  RESULT_PROCESSOR_STATUS,
  TYPE_RETURN_RESULT,
  ORDERS_STATUS
} from '@/utils/constant'
import i18n from '@/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SildebarReturnResultSupplier from './SildebarReturnResultSupplier'
import { mapState } from 'vuex'

export default {
  name: 'DetailReturnResult',
  components: {
    ListOrders,
    SildebarReturnResultSupplier,
    ProcessorResult
  },
  data() {
    return {
      listOrders: [],
      selectedOrder: {},
      appUtils,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0,
        total_page: 0
      },
      isLoading: false,
      isShowSlideBar: false,
      isLoadingOrder: false,
      isLoadingMoreOrder: false,
      supplierResult: [],
      processorResult: [],
      RESULT_TYPE,
      PRODUCT_TYPES,
      formUpload: [],
      listDisabled: [],
      formUploadSupplier: [],
      formUploadProcessor: [],
      RESULT_PROCESSOR_STATUS,
      detailResultProcessor: [],
      detailResultSupplier: [],
      keyword: '',
      TYPE_RETURN_RESULT
    }
  },
  computed: {
    ...mapState({
      orders: state => state.orders
    })
  },
  watch: {
    'orders.listOrdersInPage': {
      handler() {
        this.selectedOrder = this.listOrders?.find(
          elm => elm.id === +this.$route?.params?.id
        )
      }
    },
    async keyword(value) {
      if (value) {
        this.pagination.currentPage = 1
        this.$router.push({
          query: {
            keyword: value
          }
        })
        await this.fetchOrdersList()
        this.selectedOrder = this.listOrders[0] || {}
      }
      if (value === '' || value === null) {
        this.$router.push({ query: { page: 1 } })
        await this.fetchOrdersList()
        this.isLoadingOrder = true
        this.isLoadingOrder = false
      }
      this.fetchSupplierResults()
      this.fetchProcessorResults()
    },
    'listOrders.length'(value) {
      if (value === 0) {
        this.formUploadProcessor = []
        this.formUploadSupplier = []
      }
    }
  },
  async mounted() {
    this.keyword = this.$route.query.keyword
    this.pagination.total_page = this.$route.query.total_page
    this.pagination.currentPage = this.$route.query.page
    // await this.fetchOrdersList()
    if (this.orders.listOrdersInPage.length > 0)
      this.listOrders = this.orders.listOrdersInPage
    else await this.fetchOrdersList()
    this.selectedOrder = this.listOrders?.find(
      elm => elm.id === +this.$route?.params?.id
    )
    this.fetchSupplierResults()
    this.fetchProcessorResults()
  },
  methods: {
    getResultStatus(status) {
      switch (status) {
        case 1:
          return {
            label: 'Chưa trả kết quả',
            color: 'secondary'
          }
        case 2:
          return {
            label: 'Cần bổ sung kết quả',
            color: 'warning'
          }
        case 3:
          return {
            label: 'Đã trả kết quả',
            color: 'success'
          }
        default:
          return {
            label: 'Chưa trả kết quả',
            color: 'secondary'
          }
      }
    },
    handleSelectOrder(item) {
      if (this.selectedOrder.id === item.id) return
      else {
        this.selectedOrder = item
        this.fetchProcessorResults()
        this.fetchSupplierResults()
        this.$router.push({ params: { id: item.id } })
      }
    },
    async fetchOrdersList(currentPage) {
      const userData = appUtils.getLocalUser()
      if (!currentPage) this.isLoadingOrder = true
      if (currentPage) this.isLoadingMoreOrder = true
      await Vue.prototype.$rf
        .getRequest('CommonRequest')
        .fetchListOrdersSupplier({
          keyword: this.$route.query.keyword,
          page_size: this.pagination.perPage,
          page_num: currentPage || this.$route.query.page,
          supplier_id: userData?.supplierInfo?.id,
          sort_by: 'id',
          order: 'desc',
          type: PRODUCT_TYPES.SERVICE
        })
        .then(response => {
          const orders = response?.data?.data?.map(elm => {
            return {
              ...elm,
              // result_status: elm.result_status,
              contact_name: elm.order_contact[0]?.name
            }
          })
          this.pagination.total = response.data?.page?.total
          this.pagination.total_page = response.data?.page?.total_page
          if (!currentPage) this.listOrders = orders || []
          if (currentPage) this.listOrders = this.listOrders.concat(orders)
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('lbl_error_fetching_data'),
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
        })
        .finally(() => {
          this.isLoadingMoreOrder = false
          this.isLoadingOrder = false
        })
    },
    handleUpdate() {
      this.isShowSlideBar = true
    },
    setResultProcessor(result) {
      this.formUploadProcessor = result
    },
    async onSubmit() {
      if (this.selectedOrder?.status === ORDERS_STATUS.CANCELED) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('Đơn hàng đã huỷ, không thể trả kết quả'),
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
        return
      } else if (
        this.formUploadSupplier.filter(elm => {
          return (
            elm.results.filter(item => !item.id && item.file === null).length >
            0
          )
        }).length > 0 ||
        this.formUploadProcessor.filter(elm => {
          return (
            elm.results.filter(item => !item.id && item.file === null).length >
            0
          )
        }).length > 0
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Bạn cần tải lên file kết quả',
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
        return
      } else {
        const nodeEl = this.$createElement
        const messageVNode = nodeEl('div', {
          domProps: {
            innerHTML: this.$t(
              'Bạn có chắc chắn muốn gửi kết quả về cho bệnh nhân không?'
            )
          }
        })

        this.$bvModal
          .msgBoxConfirm([messageVNode], {
            title: this.$t('lbl_please_confirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: this.$t('lbl_yes'),
            cancelTitle: this.$t('lbl_cancel'),
            hideHeaderClose: false,
            centered: true
          })
          .then(async success => {
            if (success) {
              let listResult = []
              if (this.detailResultSupplier.length > 0) {
                listResult = this.detailResultSupplier
              } else listResult = this.detailResultProcessor
              try {
                this.isLoading = true
                let params = {}
                let attachment_groups = []
                listResult?.forEach(s_r => {
                  let document_links = []
                  let document_link = {}
                  s_r.order_item_attachment?.forEach(item => {
                    document_link = {
                      title: item.title,
                      url: item.url,
                      type: this.RESULT_TYPE.SUPPLIER,
                      status: this.RESULT_TYPE.SUPPLIER,
                      order_item_attachment_id: item.id,
                      order_item_id: item.order_item_id
                    }
                    document_links = document_links.concat(document_link)
                  })
                  let attachment_group = {
                    group_id: s_r.id,
                    document_link: document_links
                  }
                  attachment_groups.push(attachment_group)
                })
                params.attachment_groups = attachment_groups
                if (this.detailResultSupplier.length === 0) {
                  params.is_processor_result = true
                }

                await Vue.prototype.$rf
                  .getRequest('CommonRequest')
                  .supplierReturnResult(params)
                  .then(async () => {
                    await this.changeMultipleStatus()
                    await this.fetchOrdersList()
                    this.selectedOrder = this.listOrders?.find(
                      elm => elm.id === +this.$route?.params?.id
                    )
                    this.fetchProcessorResults()
                    this.fetchSupplierResults()
                    this.isLoading = false
                  })
              } catch (error) {
                console.log(error)
                this.isLoading = false
              }
            }
          })
          .catch(err => {
            // An error occurred
            console.log(err)
          })
      }
      //
    },
    setResultSupplier(result) {
      this.formUploadSupplier = result
    },
    handleReload() {
      this.isShowSlideBar = false
      this.fetchSupplierResults()
      this.fetchProcessorResults()
    },
    async fetchSupplierResults() {
      try {
        const params = {
          order_id: this.selectedOrder?.order_id,
          page_num: 1,
          page_size: 100,
          order: 'desc',
          sort_by: 'created_at',
          type: this.RESULT_TYPE.SUPPLIER
        }
        await Vue.prototype.$rf
          .getRequest('CommonRequest')
          .fetchListResult(params)
          .then(res => {
            let detailResult = []
            if (!params.order_id) {
              this.formUploadSupplier = []
              this.detailResultSupplier = []
            } else {
              detailResult = res.data?.data?.data
              this.detailResultSupplier = res.data?.data?.data
              // set form upload
              this.formUploadSupplier = detailResult?.map(elm => {
                if (elm.id) {
                  return {
                    id: elm.id,
                    name: elm.name,
                    results: elm.attachment_versions?.map(item => {
                      return {
                        id: item.id,
                        url: item.url,
                        created_at: item.updated_at,
                        updated_name: item.create_by?.name,
                        status: item.status || 1
                      }
                    }),
                    listServices: elm.order_item_attachment?.map(
                      item => item.id
                    ),
                    order_items: elm.order_item_attachment?.map(item => {
                      return {
                        id: this.selectedOrder?.order_item?.find(
                          p_o => item.order_item_id === p_o.id
                        )?.id,
                        status: item.status,
                        service_name: this.selectedOrder?.order_item?.find(
                          p_o => item.order_item_id === p_o.id
                        )?.product_name
                      }
                    })
                  }
                }
              })

              // disable những dịch vụ đã tạo phiếu
              let order_items_selected = []
              for (let i = 0; i < detailResult.length; i++) {
                let item = detailResult[i].order_item_attachment?.map(
                  elm => elm.id
                )
                order_items_selected = order_items_selected.concat(item)
              }
              this.listDisabled = this.selectedOrder?.order_item?.filter(elm =>
                order_items_selected.includes(elm.order_item_attachments[0]?.ID)
              )
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
    async fetchProcessorResults() {
      try {
        const params = {
          order_id: this.selectedOrder?.order_id,
          page_num: 1,
          page_size: 100,
          order: 'desc',
          sort_by: 'created_at',
          type: this.RESULT_TYPE.PROCESSOR,
          status: this.RESULT_PROCESSOR_STATUS.SENT
        }
        await Vue.prototype.$rf
          .getRequest('CommonRequest')
          .fetchListResult(params)
          .then(res => {
            let detailResult = []
            detailResult = res.data?.data?.data
            this.detailResultProcessor = res.data?.data?.data
            // set form upload
            this.formUploadProcessor = detailResult?.map(elm => {
              if (elm.id) {
                return {
                  id: elm.id,
                  name: elm.name,
                  results: elm.attachment_versions?.map(item => {
                    return {
                      id: item.id,
                      url: item.url,
                      created_at: item.updated_at,
                      updated_name: item.create_by?.name,
                      status: item.status || 1
                    }
                  }),
                  listServices: elm.order_item_attachment?.map(item => item.id),
                  order_items: elm.order_item_attachment?.map(item => {
                    return {
                      id: this.selectedOrder?.order_item?.find(
                        p_o => item.order_item_id === p_o.id
                      )?.id,
                      status: elm.attachment_versions,
                      service_name: this.selectedOrder?.order_item?.find(
                        p_o => item.order_item_id === p_o.id
                      )?.product_name
                    }
                  })
                }
              }
            })

            // disable những dịch vụ đã tạo phiếu
            let order_items_selected = []
            for (let i = 0; i < detailResult.length; i++) {
              let item = detailResult[i].order_item_attachment?.map(
                elm => elm.id
              )
              order_items_selected = order_items_selected.concat(item)
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
    handleScroll(value) {
      this.fetchOrdersList(value)
    },
    handleCancelForm(form) {
      this.listDisabled = form
    },
    async handleConfirmComplete() {
      const nodeEl = this.$createElement
      const messageVNode = nodeEl('div', {
        domProps: {
          innerHTML: this.$t('Bạn chăc chắn muốn hoàn thành đơn hàng không?')
        }
      })

      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: this.$t('lbl_please_confirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('lbl_yes'),
          cancelTitle: this.$t('lbl_cancel'),
          hideHeaderClose: false,
          centered: true
        })
        .then(async success => {
          if (success) {
            try {
              this.isLoading = true
              await Vue.prototype.$rf
                .getRequest('CommonRequest')
                .completeOrder(this.selectedOrder.product_order?.ID)
                .then(async () => {
                  await this.fetchOrdersList()
                  this.selectedOrder = this.listOrders?.find(
                    elm => elm.id === +this.$route?.params?.id
                  )
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: i18n.t('Xác nhận hoàn thành đơn hàng thành công'),
                      icon: 'CheckCircleIcon',
                      variant: 'success'
                    }
                  })
                  this.isLoading = false
                })
            } catch (error) {
              console.log(error)
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async changeMultipleStatus() {
      try {
        let list_order_items = {}
        if (this.formUploadSupplier.length > 0) {
          list_order_items = this.formUploadSupplier
            ?.map(elm => elm.order_items)
            ?.reduce((accumulator, currentValue) =>
              currentValue.concat(accumulator)
            )
        } else {
          list_order_items = this.formUploadProcessor
            ?.map(elm => elm.order_items)
            ?.reduce((accumulator, currentValue) =>
              currentValue.concat(accumulator)
            )
        }
        let params = []
        let order_item_status = {}
        list_order_items?.forEach(elm => {
          let steps = this.selectedOrder?.order_item?.find(
            item => item.id === elm.id
          )?.delivery_states
          order_item_status = {
            id: elm.id,
            max_steps: steps[steps.length - 1]?.status,
            order_number: steps[steps.length - 1]?.status
          }
          params.push(order_item_status)
        })
        await Vue.prototype.$rf
          .getRequest('CommonRequest')
          .changeMultipleStatus(params)
          .then(() => {
            console.log('success')
          })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.detail-return-result {
  .card-body {
    // display: flex;
    .sidebar {
      width: 240px;
      // padding-right: 8px;
      border-right: 1px solid #dbdbdb;
      height: inherit;
    }
    .result-content {
      flex: auto;
    }
    .footer {
      padding-right: 16px;
    }
    .text-base {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>