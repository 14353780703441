<template>
  <div class="processor-result" :class="type === 'processor' ? 'pb-3' : 'pb-0'">
    <div class="flex items-center justify-between mb-2" @click="isCollapse = !isCollapse">
      <h4
        class="uppercase font-bold mb-0 title"
        style="color: #000; line-height: inherit"
      >{{ title }}</h4>
      <div>
        <slot name="status"></slot>
        <!-- <feather-icon
          class="ml-2"
          icon="ChevronDownIcon"
          size="24"
          v-if="type === 'processor'"
          :style="isCollapse ? 'transform: rotate(-90deg); transition: all 0.3s ease-in-out' : 'transition: all 0.3s ease-in-out'"
        ></feather-icon>-->
      </div>
    </div>
    <!-- <b-collapse
      visible
      :id="type === 'processor' ? 'collapse-result-processor' : 'collapse-result-supplier'"
      style="transition: all 0.3s ease-in-out"
    >-->
    <div class="justify-center items-center flex h-100" style="flex: auto" v-if="isLoading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else class="flex flex-col gap-4 pb-2" id="result-collapse">
      <div v-if="this.formUpload.length === 0">Chưa có kết quả</div>
      <div v-else>
        <div v-for="(item, index) in formUpload" :key="index" class="mb-2">
          <div>
            <div class="flex align-items-end justify-between mb-1">
              <!-- Tên phiếu -->
              <b style="font-size: 16px; line-height: 24px">
                Phiếu kết quả
                <!-- <span class="form-name">{{ item.name }}</span> -->
              </b>
              <!-- Huỷ phiếu => generate lại -->
              <!-- <small
                class="btn-cancel cursor-pointer user-select-none"
                style="min-width: 75px"
                v-if="item.results.length > 0 && !item.id "
                @click="cancelForm(index)"
              >x Huỷ phiếu</small>-->
            </div>
            <ul v-for="(order_item, o_index) in item.order_items" :key="o_index">
              <li>{{ order_item.service_name }}</li>
            </ul>
            <div
              class="flex items-center gap-2 w-100"
              style="margin-top: 4px; margin-bottom: 8px"
              v-for="(result, resultIndex) in item.results"
              :key="`${index}-${resultIndex}`"
            >
              <div class="w-full result-submitted flex items-center w-full justify-between">
                <div style="width: inherit">
                  <div class="flex flex-col gap-2" style="width: inherit">
                    <div
                      class="flex gap-2 items-center"
                      :class="type === 'supplier' ? 'justify-between' : ''"
                      style="width: inherit"
                    >
                      <feather-icon icon="LinkIcon" size="16"></feather-icon>
                      <span
                        class="url"
                        :style="type === 'supplier' ? 'flex: auto' : ''"
                        @click="openFile(result.url)"
                      >{{ formatFileName(result.url) }}</span>
                      <span
                        class="text-success"
                        style="font-weight: 500"
                        v-if="type === 'supplier' && result.status === 2"
                      >Đã gửi</span>
                      <span
                        class="text-danger"
                        style="font-weight: 500"
                        v-if="type === 'supplier' && result.status === 1"
                      >Chưa gửi</span>
                    </div>
                    <small>
                      <span
                        v-if="type === 'supplier'"
                      >{{ resultIndex === 0 ? 'Upload ' : 'Bổ sung ' }}</span>
                      <span v-if=" type === 'supplier'">{{'bởi '}}</span>
                      <b v-if="type === 'supplier'">{{ result.updated_name }}</b>
                      <span v-if="type === 'processor'">{{'Trả kết quả: '}}</span>
                      <span v-if="type === 'supplier'">{{' vào lúc '}}</span>
                      <b>{{ appUtils.formatDateTime(result.created_at) }}</b>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </b-collapse> -->

    <slot name="button"></slot>
  </div>
</template>
<script>
import {
  RESULT_TYPE,
  TYPE_RETURN_RESULT,
  RESULT_PROCESSOR_STATUS
} from '@/utils/constant'
import appUtils from '@/utils/appUtils'

export default {
  name: 'ProcessorResult',
  props: {
    selectedOrder: Object,
    title: String,
    type: String,
    isReload: Boolean,
    formUpload: Array
  },
  data() {
    return {
      listProcessorResult: [],
      isLoading: false,
      RESULT_TYPE,
      appUtils,
      detailResult: [],
      TYPE_RETURN_RESULT,
      RESULT_PROCESSOR_STATUS,
      isCollapse: false
    }
  },
  watch: {
    formUpload(value) {
      if (this.type === 'supplier' && value.length === 0) {
        this.$emit('result-supplier', [])
      }
    }
  },
  methods: {
    openFile(file) {
      window.open(file)
    },
    // format file name
    formatFileName(url) {
      // let indexOfLastDot = url.lastIndexOf('.')
      let indexOfLastSlash = url?.lastIndexOf('/')
      return url?.slice(indexOfLastSlash + 1, url.length)
    }
  }
}
</script>
<style lang="scss" scoped>
.processor-result {
  padding: 0 16px 16px;
  // max-width: 280px;
  overflow: auto;
  max-height: 95%;
  flex: 1;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #0caee9;
    border-radius: 20px;
    border: 6px solid #a8bbbf;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #000d0f;
  }
}
.result-submitted {
  padding: 8px 16px;
  // border: 1px dotted;
  border-radius: 4px;
  background: #f9f9f9;
  .url {
    max-width: 90%;
    min-width: 50px;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
    font-weight: 600;
    color: #20419b;
    text-decoration: underline;
    cursor: pointer;
  }
}
.title {
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid #6e6b7b;
}
// b {
//   color: #000;
// }
</style>