<template>
  <div class="orders" id="orders">
    <div
      v-for="item in listOrders"
      :key="item.id"
      :style="selectedOrder && selectedOrder.id === item.id ? 'background: #f0f8ff' : ''"
      class="order-item flex items-center justify-between"
      @click="handleView(item)"
    >
      <div>
        <p class="uppercase mb-0">
          <b>{{item.contact_name}}</b>
        </p>
        <small>
          Đặt lúc:
          <b style="font-size: 13px;">{{appUtils.formatDateTime(item.order_date)}}</b>
        </small>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '@/utils/appUtils'

export default {
  name: 'ListOrders',
  props: {
    listOrders: Array,
    selectedOrder: Object,
    pagination: Object,
    isLoadingOrder: Boolean
  },
  data() {
    return {
      appUtils,
      paginationOrders: {
        currentPage: this.$route.query.page
      }
    }
  },
  mounted() {
    this.paginationOrders = this.pagination
    //  query selector
    const listOrerScroll = document.querySelector('#orders')
    listOrerScroll?.addEventListener('scroll', async () => {
      if (
        this.paginationOrders.currentPage < this.paginationOrders.total_page
      ) {
        if (
          listOrerScroll.scrollTop + listOrerScroll.clientHeight >=
            listOrerScroll.scrollHeight &&
          !this.isLoadingOrder
        ) {
          this.paginationOrders.currentPage =
            +this.paginationOrders.currentPage + 1
          if (this.paginationOrders.currentPage !== this.$route.query.page) {
            this.$emit('scroll', this.paginationOrders.currentPage)
          }
        }
      } else return
    })
  },
  methods: {
    handleView(item) {
      this.$emit('selectItem', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.orders {
  overflow: auto;
  height: 95%;
  // padding-right: 8px;
  max-height: 650px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #0caee9;
    border-radius: 20px;
    border: 6px solid #a8bbbf;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #000d0f;
  }
  .order-item {
    transition: all 0.3s ease-in-out;
    padding: 12px;
    border-top: 1px solid #dbdbdb;
    &:first-child {
      border-top: none;
    }
    .icon-view {
      display: none;
    }
    &:hover {
      cursor: pointer;
      .icon-view {
        display: flex !important;
      }
    }
  }
}
</style>