<template>
  <div class="layout-update-result">
    <div class="h-100">
      <div class="generate-result flex w-100 px-2">
        <div class="list-service flex-2 mr-2">
          <!-- Chọn dịch vụ để generate phiếu KQ -->
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="listServicesSelected"
              :aria-describedby="ariaDescribedby"
              name="flavour-2a"
              stacked
            >
              <div v-for="item in listServices" :key="item.id">
                <b-form-checkbox
                  :value="item"
                  :reduce="(option) => option.id"
                  class="mt-1"
                  :disabled="item.isDisable"
                >
                  <span class="cursor-pointer">{{ item.product_name }}</span>
                </b-form-checkbox>
              </div>
            </b-form-checkbox-group>
            <div class="mt-2 flex justify-content-end px-2">
              <b-button
                :disabled="!listServicesSelected.length"
                variant="outline-primary"
                class="cursor-pointer"
                :class="
                  !listServicesSelected.length ? 'cursor-not-allowed' : ''
                "
                @click="generateInputUploadFile"
                >Upload phiếu KQ</b-button
              >
            </div>
          </b-form-group>
        </div>
        <!-- Loading trả KQ -->
        <div
          class="justify-center items-center flex flex-3"
          v-if="isLoadingReturnResult"
        >
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
        <!-- Kết quả -->
        <div class="result-form flex-3 pr-1" v-else>
          <!-- Lưu ý -->
          <p v-if="formUploadConvert.length > 0" class="text-danger font-bold">
            Lưu ý: Bệnh nhân sẽ nhận được Phiếu kết quả được cập nhật/bổ sung
            gần nhất
          </p>

          <div v-if="formUploadConvert.length > 0" class="flex flex-col gap-4">
            <div v-for="(item, index) in formUploadConvert" :key="index">
              <div>
                <div class="flex align-items-end justify-between mb-1">
                  <!-- Tên phiếu -->
                  <b style="font-size: 16px; line-height: 24px">
                    Phiếu kết quả
                    <!-- <span class="form-name">{{ item.name }}</span> -->
                  </b>
                  <!-- Huỷ phiếu => generate lại -->
                  <small
                    class="btn-cancel cursor-pointer user-select-none"
                    style="min-width: 75px"
                    v-if="item.results.length > 0 && !item.id"
                    @click="cancelForm(index)"
                    >x Huỷ phiếu</small
                  >
                </div>
                <!-- Danh sách dịch vụ trong phiếu  -->
                <ul
                  v-for="(order_item, o_index) in item.order_items"
                  :key="o_index"
                >
                  <li>{{ order_item.service_name }}</li>
                </ul>
                <div
                  class="flex items-center gap-2 w-100"
                  style="margin-top: 4px; margin-bottom: 8px"
                  v-for="(result, resultIndex) in item.results"
                  :key="`${index}-${resultIndex}`"
                >
                  <!-- Khi chưa có KQ  -->
                  <div v-if="!result.id" class="w-100 flex items-center gap-2">
                    <b-form-file
                      :ref="`uploadResult${resultIndex}`"
                      v-model="result.file"
                      :placeholder="$t('lbl_upload')"
                      accept
                      no-drop
                      :browse-text="$t('lbl_choose_file')"
                      class="cs-select-file cursor-pointer"
                      @change="handleChangeFile"
                    ></b-form-file>
                    <small
                      class="delete cursor-pointer"
                      v-if="item.results.length !== 1"
                      @click="deleteForm(item.results, resultIndex)"
                    >
                      <feather-icon
                        class="cursor-pointer"
                        icon="XIcon"
                        size="20"
                      />
                    </small>
                  </div>
                  <!-- Khi dã có KQ -->
                  <div
                    v-else
                    class="w-full result-submitted flex items-center w-full justify-between"
                  >
                    <div class="w-100">
                      <div class="flex flex-col gap-2 w-100">
                        <div class="flex gap-2 items-center">
                          <feather-icon
                            icon="LinkIcon"
                            size="16"
                          ></feather-icon>
                          <span class="url" @click="openFile(result.url)">{{
                            formatFileName(result.url)
                          }}</span>
                          <span
                            class="text-success"
                            style="font-weight: 500"
                            v-if="result.status === 2"
                            >Đã gửi</span
                          >
                        </div>
                        <small>
                          {{ resultIndex === 0 ? "Upload " : "Bổ sung " }}
                          bởi
                          <b>{{ result.updated_name }}</b>
                          vào lúc
                          <b>{{
                            appUtils.formatDateTime(result.created_at)
                          }}</b>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Bổ sung KQ: Mỗi lần chỉ được bổ sung 1 file KQ -->
              <div
                class="btn-cancel cursor-pointer text-blue"
                @click="addResult(item)"
                v-if="!isAddMoreResult(item)"
              >
                + Bổ sung kết quả
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import appUtils from "@/utils/appUtils";
import { TYPE_RETURN_RESULT, RESULT_TYPE } from "@/utils/constant";
export default {
  name: "SupplierUpdateResult",
  props: {
    listOrderItems: Array, // danh sách order_items (ds dịch vụ)
    orderSelected: Object, // detail dịch vụ được chọn
    isLoadingReturnResult: Boolean,
    formUpload: Array,
    listDisabled: Array,
  },
  data() {
    return {
      appUtils,
      listServicesSelected: [],
      listServices: this.listOrderItems,
      TYPE_RETURN_RESULT,
      detailResult: [],
      RESULT_TYPE,
      formUploadConvert: [],
      listDisabledConvert: [],
    };
  },
  watch: {
    // disable những dịch vụ đã generate phiếu
    listDisabled(value) {
      this.listDisabledConvert = value;
      this.listServices = this.listServices?.map((elm) => {
        return {
          ...elm,
          isDisable: value.find((item) => item.id === elm.id) ? true : false,
        };
      });
    },
    formUpload(value) {
      this.formUploadConvert = value;
      this.$emit("result_supplier", this.formUploadConvert);
    },
    listOrderItems(value) {
      this.listServices = value;
    },
    orderSelected() {
      this.listDisabledConvert = [];
    },
  },

  methods: {
    // Thêm 1 ô input tải file kết quả khi chọn dịch vụ
    generateInputUploadFile() {
      this.formUploadConvert.push({
        // id: new Date().getTime(),
        name: "Phiếu kết quả",
        results: [
          {
            file: null,
          },
        ],
        listServices: this.listServicesSelected?.map(
          (elm) => elm?.order_item_attachments[0]?.ID
        ),
        order_items: this.listServicesSelected?.map((elm) => {
          return {
            id: elm.id,
            service_name: elm.product_name,
          };
        }),
      });
      this.listDisabledConvert?.push(...this.listServicesSelected);
      this.listServicesSelected = [];
    },
    // Thêm 1 ô input tải file kết quả khi ấn bổ sung kết quả
    addResult(form) {
      if (this.isAddMoreResult(form)) return;
      else {
        form.results.push({
          file: null,
        });
        this.listServicesSelected = [];
      }
    },
    // Check nút bổ sung kết quả
    isAddMoreResult(form) {
      return form.results?.filter((elm) => !elm.id).length === 1;
    },
    // Xoá ô input tải file lên
    deleteForm(form, index) {
      form.splice(index, 1);
    },
    // Huỷ phiếu đã generate
    cancelForm(index) {
      // enable những dịch vụ vừa huỷ phiếu
      this.listDisabledConvert = this.listDisabledConvert?.filter((elm) => {
        return (
          this.formUploadConvert[index].listServices?.includes(
            elm.order_item_attachments[0]?.ID
          ) === false
        );
      });
      this.formUploadConvert.splice(index, 1);
      this.$emit("cancel-form", this.listDisabledConvert);
    },
    // event khi tải file lên
    handleChangeFile() {
      this.$emit("result_supplier", this.formUploadConvert);
    },
    // huỷ không cập nhật kết quả phía supplier nữa
    handleCancel() {
      this.$emit("cancel");
      this.listDisabledConvert = [];
    },
    // format file name
    formatFileName(url) {
      // let indexOfLastDot = url.lastIndexOf('.')
      let indexOfLastSlash = url?.lastIndexOf("/");
      return url?.slice(indexOfLastSlash + 1, url.length);
    },
    //open file
    openFile(file) {
      window.open(file);
    },
  },
};
</script>
<style lang="scss" scoped>
.layout-update-result {
  padding: 0 16px 16px 16px;
  flex: 3;
  // width: 100%;
}
.result-submitted {
  padding: 8px 16px;
  // border: 1px dotted;
  border-radius: 4px;
  background: #f9f9f9;
  .url {
    flex: auto;
    // max-width: 300px;
    min-width: 50px;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
    font-weight: 600;
    color: #20419b;
    text-decoration: underline;
    cursor: pointer;
  }
}
.flex-3 {
  flex: 3;
}
.flex-2 {
  flex: 2;
}
.text-blue {
  color: #20419b;
}

.generate-result {
  // height: 88%;
}
.result-form {
  overflow: auto;
}
</style>
<style lang="scss">
.result-form,
.b-sidebar-body {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #0caee9;
    border-radius: 20px;
    border: 6px solid #a8bbbf;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #000d0f;
  }
}
</style>